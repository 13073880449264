import { Injectable } from '@angular/core';
import { DataService } from '../_architecture/data.service';
import { Result, PagedList } from '../_architecture/envelopes';
import { WorkSchedule } from '../_models/workschedule.model';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TransporteColaborador, Relatorio, Filtro } from '../_models/report.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private dataService: DataService) { }

  public getReportList(filtro: Filtro) {
    let uri: string;

    switch (filtro.codRel) {
      case 1:
        uri = 'transporte-colaboradores';
        break;
      case 2:
        uri = 'transporte-colaboradores-completo';
        break;
      case 3:
        uri = 'refeicao-colaboradores';
        break;
      case 4:
        uri = 'refeicao-colaboradores-completo';
        break;
      case 4.1:
        uri = 'transporte-colaboradores-arquivo';
        break;
      case 5:
        uri = 'seguranca-patrimonial';
        break;
      case 5.1:
        uri = 'seguranca-patrimonial-arquivo';
        break;
      case 6:
        uri = 'suporteit';
        break;
      case 6.1:
        uri = 'suporteit-arquivo';
        break;
    }

    return this.dataService.post<Result<any[]>>('/report/' + uri, filtro);
  }
}
