export class TransporteColaborador {
    data:string;
    horario: string;
    linha: string;
    codEnd: string;
    cCusto: number;
    quantidade: number;
}

export class RefeicaoColaborador {
    data:string;
    qtdJantar: string;
    qtdAlmoco: string;
    qtdCeia: string;
    qtdDesjejum: string;
    qtdKitMadrugada: string;
    qtdKitTarde: string;
}

export class Relatorio {
    codRel: number;
    dataInicio:string;
    dataFim: string;    
    descricao: string;
}

export class Filtro {
    codRel: number;
    dataInicio:string;
    dataFim: string;
}