import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';


@Component({
  selector: 'app-param-modal',
  templateUrl: './param-modal.component.html',
  styleUrls: ['./param-modal.component.css']
})
export class ParamModalComponent implements OnInit {

  modalOptions: any =  {
    backdropColor: 'rgba(0,0,0,0.7)'
  };

  constructor(private dialogService: SimpleModalService) { }

  ngOnInit() {
  }
}
