import { Component, OnInit } from '@angular/core';
import { UtilService } from '../_services/util.service';
import { IDatePickerConfig } from 'ng2-date-picker';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkscheduleService } from '../_services/workschedule.service';
import { WorkSchedule, WorkScheduleTotais } from '../_models/workschedule.model';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { AreaServices, FoodServices, Area } from '../_models/area.model';
import { Colaborator } from '../_models/colaborator.model';
import { ParameterDetail, ParameterItem } from '../_models/parameter.model';
import { SimpleModalComponent, SimpleModalService } from "ngx-simple-modal";
import { LinhaTransporte } from '../_models/linha-transporte.model';
import { Location } from '@angular/common';
import { dateLessThanValidator } from './workschedule.validator';
import { OrderPipe } from 'ngx-order-pipe';
import { UserService } from '../_services/user.service';
import { AdminService } from '../_services/admin.service';

export interface IWorkscheduleConfirmation {
  workschedule: WorkSchedule;
}

@Component({
  selector: 'app-workschedule',
  templateUrl: './workschedule.component.html',
  styleUrls: ['./workschedule.component.css']
})
export class WorkscheduleComponent
  extends SimpleModalComponent<IWorkscheduleConfirmation, string>
  implements IWorkscheduleConfirmation, OnInit {
  showEmploye: boolean = false;
  workschedule: WorkSchedule;
  workScheduleTotais: WorkScheduleTotais[];
  showWorkSchedule: boolean;
  showWorkScheduleConfirmation: boolean;
  submitted = false;
  searchText: string;
  foodServicesColaboradores: FoodServices[];
  foodServicesTerceiros: FoodServices[];
  foodServices: ParameterDetail[];
  foodServicesBySchedule: ParameterDetail[];
  horarios: ParameterItem[];
  areas: AreaServices[];
  colaborators: Colaborator[];
  linhasTransporte: LinhaTransporte[];
  selectedHorario: number;
  selectedArea: Area;
  periodFrom: Date;
  periodTo: Date;
  selectedAll: boolean;
  selectedAllTransport: boolean;
  checkedDisabled: boolean = true;
  checkedDisabledTransport: boolean = true;
  scheduleForm: FormGroup;
  foodServicePanelOpened: string = '';
  tipoColab: string = '';
  searchMode: string = '';
  errors: any[] = [];

  //Fields to order the data
  order: string = 'name';
  reverse: boolean = true;
  sortedColaborators: Colaborator[];

  dateConfig: IDatePickerConfig = {
    locale: 'pt',
    format: 'DD/MM/YYYY',
    showMultipleYearsNavigation: true,
    disableKeypress: true,
    min: '03/12/2019'
  };
  modalOptions: any = {
    backdropColor: 'rgba(0,0,0,0.7)'
  };

  constructor(dialogService: SimpleModalService,
    private router: Router,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private workScheduleService: WorkscheduleService,
    private adminService: AdminService,
    private userService: UserService,
    private route: ActivatedRoute,
    private location: Location,
    private orderPipe: OrderPipe) {

    super();

    this.workschedule = new WorkSchedule();
    this.showWorkScheduleConfirmation = false;
    this.showWorkSchedule = true;
    this.colaborators = [];
    this.sortedColaborators = orderPipe.transform(this.colaborators, this.order);
  }

  createForm() {
    this.scheduleForm = this.formBuilder.group({
      searchText: [this.searchText],
      codEnd: [this.workschedule.codEnd, Validators.required],
      dataInicio: [this.workschedule.dataInicio, Validators.required],
      dataFim: [this.workschedule.dataFim, Validators.required],
      periodo: [this.workschedule.periodo, Validators.required],
      justificativa: [this.workschedule.justificativa, Validators.required],
      totalColaboradores: [this.workschedule.totalColaboradores],
      considerarFdsFeriado: [this.workschedule.considerarFdsFeriado, Validators.required],
      suporteIt: [this.workschedule.suporteIt],
      totalTerceiros: [this.workschedule.totalTerceiros],
    },
      {
        validator: dateLessThanValidator
      });
  }

  get form() { return this.scheduleForm; }

  ngOnInit() {

    //Carrega as áreas (LIOF, LIO, etc)
    this.utilService.listAreas().subscribe((result) => {
      this.areas = result.content;
    });

    //Carrega os parâmetros de horários
    this.utilService.listHours().subscribe((result) => {
      this.horarios = result.content;
    });

    //Carrega os serviços de alimentação cadastrados
    this.utilService.listFoodServices().subscribe((result) => {
      this.foodServices = result.content;

      this.foodServicesColaboradores = [];
      this.foodServicesTerceiros = [];

      this.foodServices.forEach(foodService => {
        //Carrega o controle de serviços de alimentação de colaboradores
        this.foodServicesColaboradores.push({
          description: foodService.parameter1,
          idDetalhe: foodService.id,
          quantidade: 0,
          tipoColaborador: 1 //Funcionários
        });

        //Carrega o controle de serviços de alimentação de terceiros
        this.foodServicesTerceiros.push({
          description: foodService.parameter1,
          idDetalhe: foodService.id,
          quantidade: 0,
          tipoColaborador: 2 //Terceiros
        });
      });
    });

    //Carrega as linhas de transporte
    this.utilService.listTransportLines().subscribe(result => {
      this.linhasTransporte = result.content;
    });

    //Cria o formulário
    this.createForm();

    this.route.paramMap.subscribe(params => {
      //Se receber o parâmetro id
      if (null !== params.get('id')) {
        //Carrega a programação
        this.loadWorkschedule(parseInt(params.get('id')));
      }
    });
  }

  //Ao alterar o controle de áreas
  changeArea() {
    this.utilService.listColaborators(this.selectedArea.codEnd).subscribe((result) => {
      this.colaborators = result.content;

      if ((undefined !== this.colaborators && this.colaborators.length > 0) && this.selectedHorario > 0) {
        this.checkedDisabled = false;
        this.checkedDisabledTransport = false;
        this.foodServicePanelOpened = 'show';

        this.colaborators.forEach(colaborator => {
          colaborator.disabled = false;
        });
      }
      else {
        this.colaborators.forEach(colaborator => {
          colaborator.disabled = true;
        });
      }

      this.workschedule.cCusto = this.selectedArea.costCenter;
      this.workschedule.codEnd = this.selectedArea.codEnd;
      this.showEmploye = true;
    });
  }

  setOrder(value: string) {
    if (this.order === value)
      this.reverse = !this.reverse;
    this.order = value;
  }

  //Ao alterar o controle de horários
  changeHorario() {
    this.utilService.getFoodServicesByItem(this.selectedHorario).subscribe((result) => {
      this.foodServicesBySchedule = result.content;
      this.workschedule.idPeriodo = this.selectedHorario;
      this.sumFoodServices();
    });

    if ((undefined !== this.colaborators && this.colaborators.length > 0) && this.selectedHorario > 0) {
      this.checkedDisabled = false;
      this.checkedDisabledTransport = false;
      this.foodServicePanelOpened = 'show';
      this.colaborators.forEach(colaborator => {
        colaborator.disabled = false;
      });
    }
  }

  //Carrega a programação de trabalho a partir do id
  loadWorkschedule(id: number) {
    this.workScheduleService.getWorkSchedules(id).subscribe((result) => {

      this.workschedule = result.content;
      this.selectedHorario = this.workschedule.idPeriodo;
      this.selectedArea = { costCenter: this.workschedule.cCusto, codEnd: this.workschedule.codEnd };
      this.colaborators = this.workschedule.colaboradores;
      this.checkedDisabled = false;
      this.checkedDisabledTransport = false;
      this.showEmploye = true;

      this.utilService.getFoodServicesByItem(this.selectedHorario).subscribe((result) => {
        this.foodServicesBySchedule = result.content;
      });

      this.workschedule.services.forEach(servico => {
        switch (servico.tipoColaborador) {
          case 1: //Funcionário
            var index = this.foodServicesColaboradores.findIndex(s => s.tipoColaborador === servico.tipoColaborador && s.idDetalhe === servico.idDetalhe);

            if (-1 !== index)
              this.foodServicesColaboradores[index].quantidade = servico.quantidade;
            break;

          case 2: //Terceiro
            var index = this.foodServicesTerceiros.findIndex(s => s.tipoColaborador === servico.tipoColaborador && s.idDetalhe === servico.idDetalhe);

            if (-1 !== index)
              this.foodServicesTerceiros[index].quantidade = servico.quantidade;
            break;
        }
      });

      // Carrega flag de suporte IT
      this.workschedule.services.forEach(servico => {
        if(servico.idDetalhe == 12) {
          this.workschedule.suporteIt = true;
        }
      });

      if (this.route.toString().indexOf('re-schedule') > -1)
        this.workschedule.id = 0;

      this.foodServicePanelOpened = 'show';
    });
  }

  hasError(prefix: string) {
    return this.errors.indexOf(prefix) > -1;
  }

  isEdit() {
    return this.workschedule.id > 0;
  }

  clear() {
    this.createForm();
  }

  back() {
    this.hideSummary();
  }

  showSummary() {
    this.showWorkSchedule = false;
    this.showWorkScheduleConfirmation = true;
  }

  hideSummary() {
    this.showWorkSchedule = true;
    this.showWorkScheduleConfirmation = false;
  }

  calculaSolicitacao() {
    //Reinicia o resumo
    this.workScheduleTotais = [];
    var quantidadeTransporteColaboradores = 0;
    var quantidadeTransporteTerceiros = 0;

    //Pega os colaboradores selecionados
    this.colaborators.forEach((colaborador) => {
      if (colaborador.checkedTransport) {
        if (colaborador.linhaTransporte > 0) {
          if (colaborador.company === '01')
            quantidadeTransporteColaboradores++;
          else
            quantidadeTransporteTerceiros++;
        }
      }
    });

    //Soma as linhas de transporte
    this.workScheduleTotais.push({
      servico: 'TRANSPORTE',
      quantidadeColaboradores: quantidadeTransporteColaboradores,
      quantidadeTerceiros: quantidadeTransporteTerceiros
    });

    //Soma as quantidades do suporte de IT
    if(this.workschedule.suporteIt == true) {
      this.workScheduleTotais.push({
        servico: 'SUPORTE IT',
        quantidadeColaboradores: this.workschedule.totalColaboradores,
        quantidadeTerceiros: this.workschedule.totalTerceiros
      });
    }

    //Soma os serviços de alimentação de colaboradores
    this.foodServicesBySchedule.forEach(servico => {
      this.workScheduleTotais.push({
        servico: servico.descParameter2,
        quantidadeColaboradores: this.foodServicesColaboradores.find(service => service.idDetalhe.toString() === servico.parameter2).quantidade,
        quantidadeTerceiros: this.foodServicesTerceiros.find(service => service.idDetalhe.toString() === servico.parameter2).quantidade
      });
    });
  }

  onSubmit() {
    this.submitted = true;

    //Pega os colaboradores selecionados
    this.errors = [];
    this.workschedule.colaboradores = [];
    this.colaborators.forEach((obj) => {
      if (obj.checked || (obj.checkedTransport && obj.linhaTransporte !== null)) {
        this.workschedule.colaboradores.push(obj);
      }
    });

    if (this.colaborators.length > 0 && this.workschedule.colaboradores.length === 0) {
      this.errors.push('colaboratorsNotSelected');

      //Se houver algum erro do formulário
      if (this.scheduleForm.invalid || this.errors.length > 0) {
        //Sobe a barra de rolagem para o topo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return;
      }
    }

    const user = this.userService.getCurrentUser();

  
    this.workScheduleService.validate(this.workschedule).subscribe(
      result => {
        if(result.success == true) {
          this.calculaSolicitacao();
          this.showSummary();
        } else {
          var errors: ValidationErrors[] = [];

          if (result.messages.length > 0) {
            result.messages.forEach(m => {
              if (m.key == "prazo-expirado") {
                this.errors.push('dateExpired');
              }
              let i = this.colaborators.findIndex(c => c.register.toString() === m.key);
              if (i > -1) {
                this.colaborators[i].error = true;
                this.errors.push('registerExists');
              }
            });
          }
          
          var index = this.errors.indexOf('prazo-expirado') > -1;
          var index = this.errors.indexOf('registerExists') > -1;

          //Se houver algum erro do formulário
          if (this.scheduleForm.invalid || this.errors.length > 0) {
            //Sobe a barra de rolagem para o topo
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            return;
          }
        }
      }
    );
  }

  //Salva a programação de trabalho
  save() {
    const user = this.userService.getCurrentUser();
    this.workschedule.registroUsuario = +user.registry;
    this.workschedule.empresaUsuario = '01';

    //Pega os colaboradores selecionados
    this.workschedule.colaboradores = [];
    this.colaborators.forEach((obj) => {

      if (!obj.checkedTransport)
        obj.linhaTransporte = null;

      if (obj.checked || (obj.checkedTransport && obj.linhaTransporte !== null)) {
        this.workschedule.colaboradores.push(obj);
      }
    });

    //Pega os serviços
    this.workschedule.services = [];
    //Serviços para colaboradores
    this.foodServicesColaboradores.forEach(servico => {
      if (servico.quantidade > 0) {
        this.workschedule.services.push({
          id: 0,
          idDetalhe: servico.idDetalhe,
          quantidade: servico.quantidade,
          tipoColaborador: 1
        });
      }
    });

    //Serviços para terceiros
    this.foodServicesTerceiros.forEach(servico => {
      if (servico.quantidade > 0) {
        this.workschedule.services.push({
          id: 0,
          idDetalhe: servico.idDetalhe,
          quantidade: servico.quantidade,
          tipoColaborador: 2
        });
      }
    });

    // Incluir suporte de IT
    if (this.workschedule.suporteIt == true) {
      this.workschedule.services.push({
        id: 0,
        idDetalhe: 12,
        quantidade: this.workschedule.totalColaboradores,
        tipoColaborador: 1
      });
      this.workschedule.services.push({
        id: 0,
        idDetalhe: 12,
        quantidade: this.workschedule.totalTerceiros,
        tipoColaborador: 2
      });
    }

    if (this.workschedule.id > 0)
      this.update();
    else
      this.create();
  }

  //Cria uma nova programação de trabalho
  create() {
    this.workScheduleService.create(this.workschedule)
      .subscribe(
        result => {
          this.router.navigateByUrl('/history');
        },
        error => {
        });
  }

  //Atualiza uma programação de trabalho
  update() {
    this.workScheduleService.update(this.workschedule)
      .subscribe(
        result => {
          this.router.navigateByUrl('/history');
        },
        error => {
        });
  }

  //Realiza o cálculo relativo aos serviços de alimentação baseados nos parâmetros cadastrados e na quantidade de colaboradores e terceiros selecionados
  sumFoodServices() {
    for (var x = 0; x < this.foodServicesColaboradores.length; x++) {
      const service = this.foodServicesBySchedule.find(service => service.parameter2 === this.foodServicesColaboradores[x].idDetalhe.toString());

      if (undefined !== service) {
        this.foodServicesColaboradores[x].quantidade = (
          undefined === this.workschedule.totalColaboradores ?
            0 :
            this.colaborators.filter(colab => colab.checked && colab.company === '01').length
        );
      }
    }

    for (var x = 0; x < this.foodServicesTerceiros.length; x++) {
      const service = this.foodServicesBySchedule.find(service => service.parameter2 === this.foodServicesTerceiros[x].idDetalhe.toString());

      if (undefined !== service) {
        this.foodServicesTerceiros[x].quantidade = (
          undefined === this.workschedule.totalTerceiros ?
            0 :
            this.colaborators.filter(colab => colab.checked && colab.company !== '01').length
        );
      }
    }
  }

  checkAll(ev) {
    this.colaborators.filter(x => ((this.tipoColab == 'C' && x.company === '01') || (this.tipoColab == 'T' && x.company !== '01') || this.tipoColab == '')).forEach((obj) => { obj.checked = ev.target.checked; });
    this.workschedule.totalColaboradores = this.colaborators.filter(x => ((x.checked || x.checkedTransport) && x.company === '01')).length;
    this.workschedule.totalTerceiros = this.colaborators.filter(x => ((x.checked || x.checkedTransport) && x.company !== '01')).length;

    this.sumFoodServices();
  }

  checkAllTransport(ev) {
    this.colaborators.filter(x => ((this.tipoColab == 'C' && x.company === '01') || (this.tipoColab == 'T' && x.company !== '01') || this.tipoColab == '')).forEach((obj) => { obj.checkedTransport = ev.target.checked; });
    this.workschedule.totalColaboradores = this.colaborators.filter(x => ((x.checked || x.checkedTransport) && x.company === '01')).length;
    this.workschedule.totalTerceiros = this.colaborators.filter(x => ((x.checked || x.checkedTransport) && x.company !== '01')).length;
  }

  checkIfAllSelected(colaborator: Colaborator) {
    colaborator.error = false;

    this.workschedule.totalColaboradores = this.colaborators.filter(x => ((x.checked || x.checkedTransport) && x.company === '01')).length;
    this.workschedule.totalTerceiros = this.colaborators.filter(x => ((x.checked || x.checkedTransport) && x.company !== '01')).length;

    this.selectedAll = this.colaborators.filter(x => ((this.tipoColab == 'C' && x.company === '01') || (this.tipoColab == 'T' && x.company !== '01') || this.tipoColab == '')).every(function (item: Colaborator) {
      return item.checked === true;
    });

    this.sumFoodServices();
  }

  checkIfAllSelectedTransport(colaborator: Colaborator) {
    colaborator.error = false;

    this.workschedule.totalColaboradores = this.colaborators.filter(x => ((x.checked || x.checkedTransport) && x.company === '01')).length;
    this.workschedule.totalTerceiros = this.colaborators.filter(x => ((x.checked || x.checkedTransport) && x.company !== '01')).length;

    this.selectedAllTransport = this.colaborators.filter(x => ((this.tipoColab == 'C' && x.company === '01') || (this.tipoColab == 'T' && x.company !== '01') || this.tipoColab == '')).every(function (item: Colaborator) {
      return item.checkedTransport === true;
    });

    //this.sumFoodServices();
  }

  isThird(tpFun: any) {
    const myarr = ['11', '12', 'EM', '04'];
    return (myarr.indexOf(tpFun) > -1);
  }

  isIntern(tpFun: any) {
    const myarr = ['02'];
    return (myarr.indexOf(tpFun) > -1);
  }

  compareArea(area1: Area, area2: Area) {
    return area1 && area2 ? area1.codEnd === area2.codEnd && area1.costCenter === area2.costCenter : area1 === area2;
  }
}
