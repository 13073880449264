import { Component, OnInit } from '@angular/core';
import { IDatePickerConfig } from 'ng2-date-picker';
import { SimpleModalComponent } from "ngx-simple-modal";
import { ParameterItem } from 'src/app/_models/parameter.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { dateLessThanValidator } from './create-parameter-item.validator';
import { AdminService } from 'src/app/_services/admin.service';

export interface CreateParameterItemModel {
  parameter: ParameterItem;
}

@Component({
  selector: 'app-create-parameter-item',
  templateUrl: './create-parameter-item.component.html',
  styleUrls: ['./create-parameter-item.component.css']
})
export class CreateParameterItemComponent extends SimpleModalComponent<CreateParameterItemModel, ParameterItem> implements CreateParameterItemModel, OnInit {
  parameter: ParameterItem;
  parameterFormItem: FormGroup;

  dateConfig: IDatePickerConfig = {
    locale: 'pt',
    format: 'DD/MM/YYYY',
    showMultipleYearsNavigation: true,
    disableKeypress: true
  };

  constructor(private formBuilder: FormBuilder,
    private adminService: AdminService) {

    super();
    this.parameter = new ParameterItem()
  }

  ngOnInit() {
    this.parameterFormItem = new FormGroup({
      description: new FormControl(),
      parameter1: new FormControl(),
      parameter2: new FormControl(),
      parameter3: new FormControl(),
      startDate: new FormControl(),
      endDate: new FormControl()
    });

    this.createForm();
  }

  createForm() {
    this.parameterFormItem = this.formBuilder.group({
      parameter1: [this.parameter.parameter1, Validators.required],
      parameter2: [this.parameter.parameter2, Validators.required],
      parameter3: [this.parameter.parameter3, Validators.required],
      description: [this.parameter.description, Validators.required],
      startDate: [this.parameter.startDate, [Validators.required]],
      endDate: [this.parameter.endDate, Validators.required]
    }, { validator: dateLessThanValidator });
  }

  get f() { return this.parameterFormItem.controls; }

  formErrors(name: string) {
    if (this.parameterFormItem.touched) {
      if (this.parameterFormItem.errors) {
        return this.parameterFormItem.errors[name];
      } else {
        return false;
      }
    }
  }

  confirm() {
    if (this.parameter.id > 0)
      this.update();
    else
      this.create();

    this.result = this.parameter;
    this.close();
  }

  update() {
    this.adminService.updateParameterItem(this.parameter).subscribe((result) => {
      console.log('Parameter Item Updated!');
    });
  }

  create() {
    this.adminService.createParameterItem(this.parameter).subscribe((result) => {
      this.parameter.id = result.content.id;
      console.log('Parameter Item Created!');
    });
  }
}
