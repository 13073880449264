import { Injectable } from '@angular/core';
import { CurrentUser } from '../_models/current-user.model';
import { AuthService } from '../_guards/auth.service';
import { AuthContext } from '../_guards/auth-context';

@Injectable()
export class UserService {
  userLogged: CurrentUser;

  constructor(private authService: AuthService) { }

  getCurrentUser() : AuthContext {
    return JSON.parse(localStorage.getItem('UserInfo'));
  }

  userIsLogged() {
    
    const user = this.getCurrentUser();
    const dateNow = new Date();

    if (user) {
      return this.authService.isLoggedIn;
    }

    return false;
  }

  logout(){
    this.authService.logout();
  }
}
