import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { UtilService } from '../_services/util.service';
import { HtmlParser } from '@angular/compiler';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  informationText: string;

  constructor(private userService: UserService, 
              private utilService: UtilService) { }

  ngOnInit() {
    this.utilService.getHomeScreenMessage().subscribe((result) => {
      this.informationText = result;
    });
  }

  getHtmlText() {
    return this.informationText;
  }
}