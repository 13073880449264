import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { WorkSchedule } from 'src/app/_models/workschedule.model';

export interface WorkscheduleModelDeleteModel {
  workschedule: WorkSchedule;
}

@Component({
  selector: 'app-workschedule-modal-delete.component.html',
  templateUrl: './workschedule-modal-delete.component.html',
  styleUrls: ['./workschedule-modal-delete.component.css']
})

export class WorkscheduleModelDeleteComponent
  extends SimpleModalComponent<WorkscheduleModelDeleteModel, boolean>
  implements WorkscheduleModelDeleteModel, OnInit {

  workschedule: WorkSchedule;

  constructor(dialogService: SimpleModalService) {
    super();
    this.workschedule = new WorkSchedule();
  }

  ngOnInit() {
  }

  confirm() {
    this.result = true;
    this.close();
  }

  cancel() {
    this.result = false;
    this.close();
  }
}
