import { PipeTransform, Pipe } from "@angular/core";
import * as _ from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortPipe implements PipeTransform {

    transform(value: any[], column: string, order = '', reverse: boolean = false): any[] {
        if (!value || !column || column === '' || order === '') { return value; } //não é um array
        if (value.length <= 1) { return value; } //array de um único item

        return _.orderBy(value, [column], [order]);
    }
}