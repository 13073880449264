import { Injectable } from '@angular/core';
import { DataService } from '../_architecture/data.service';
import { Result, PagedList } from '../_architecture/envelopes';
import { WorkSchedule, WorkScheduleTotais } from '../_models/workschedule.model';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkscheduleService {
  public workschedule: WorkSchedule;
  public workscheduleTotais: WorkScheduleTotais[];

  constructor(private dataService: DataService) { }

  public listWorkSchedule(searchMode: string) {
    const params = new HttpParams().append('filter.searchtext', searchMode ? searchMode : '');
    return this.dataService.get<Result<WorkSchedule[]>>('/workschedule/list', params);
  }

  public create(workschedule: WorkSchedule) {
    return this.dataService.post<Result<any>>('/workschedule/add', workschedule);
  }

  public update(workschedule: WorkSchedule) {
    return this.dataService.put<Result<any>>('/workschedule/put', workschedule);
  }

  public delete(id: any) {
    return this.dataService.delete<Result<any>>('/workschedule/delete/' + id, null);
  }

  public listWorkSchedules(workschedule: WorkSchedule) {
    return this.dataService.post<Result<any>>('/workschedule/workschedules', workschedule);
  }

  public getWorkSchedules(id: any) {
    return this.dataService.get<Result<any>>('/workschedule/' + id, null);
  }

  public verifyExistingRegisterScheduled(workschedule: WorkSchedule) {
    return this.dataService.post<Result<any>>('/workschedule/existing-register-scheduled', workschedule);
  }
  
  public verifyBuslineScheduled(workschedule: WorkSchedule) {
    return this.dataService.post<Result<any>>('/workschedule/busline-scheduled', workschedule);
  }
  
  public validate(workschedule: WorkSchedule) {
    return this.dataService.post<Result<any>>('/workschedule/validate', workschedule);
  }
}
