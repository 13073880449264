import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class DataService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private configurationService: ConfigurationService
  ) { }

  webApiEndpoint = this.configurationService.getWebApiEndpoint();

  public getAny<T>(url: string, params: HttpParams = null, headers: HttpHeaders = null) {
    
    let apiReturn = this.http.get<T>(encodeURI(url), { headers, params }).
      pipe(catchError(error => { this.router.navigate(['error']); return of(error); }));
    
    return apiReturn;
  }

  public get<T>(url: string, params: HttpParams = null, headers: HttpHeaders = null) {
    
    let apiReturn = this.http.get<T>(encodeURI(this.webApiEndpoint + url), { headers, params }).
      pipe(catchError(error => { this.router.navigate(['error']); return of(error); }));
    
    return apiReturn;
  }

  public post<T>(url: string, body: any) {
    
    let apiReturn = this.http.post<T>(encodeURI(this.webApiEndpoint + url), body).
      pipe(catchError(error => {
        console.log(error);
        this.router.navigate(['error']);
        return of(error);
      }));
      
      return apiReturn;
  }

  public postAny(url: string, body: any, options: any) {
    
    let apiReturn = this.http.post(encodeURI(this.webApiEndpoint + url), body, options).
      pipe(catchError(error => { this.router.navigate(['error']); return of(error); }));
    
    return apiReturn;
  }

  public put<T>(url: string, body: any) {
    
    let apiReturn = this.http.put<T>(encodeURI(this.webApiEndpoint + url), body).
      pipe(catchError(error => { this.router.navigate(['error']); return of(error); }));
    
    return apiReturn;
  }

  public delete<T>(url: string, body: any = null) {
    
    const options: any = { body: body };
    let apiReturn = this.http.delete<T>(encodeURI(this.webApiEndpoint + url), options).
      pipe(catchError(error => { this.router.navigate(['error']); return of(error); }));
    return apiReturn;
  }
}
