import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { from } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _authService: AuthService) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let logged = await this._authService.isLoggedIn();

    if (logged) {
        return true;
    } else {
      this._authService.login();
      return false;
    }
  }
}
