import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from "ngx-simple-modal";
import { Parameter } from 'src/app/_models/parameter.model';
import { AdminService } from 'src/app/_services/admin.service';

export interface ConfirmParameterModel {
  parameter: Parameter;
}

@Component({
  selector: 'app-confirm-parameter',
  templateUrl: './confirm-parameter.component.html',
  styleUrls: ['./confirm-parameter.component.css']
})
export class ConfirmParameterComponent extends SimpleModalComponent<ConfirmParameterModel, boolean> implements ConfirmParameterModel, OnInit {

  parameter: Parameter;

  constructor(
    private adminService: AdminService) {

    super();
    this.parameter = new Parameter();
  }

  ngOnInit() {
  }

  confirm() {
    this.adminService.deleteParameter(this.parameter.id).subscribe((result) => {
      console.log('Parameter Deleted!');
    });

    this.result = true;
    this.close();
  }
}
