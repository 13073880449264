export class Parameter {
    id: number;
    code: string;
    description: string;
    startDate: string;
    endDate: string;
    usuario: string;
}

export class ParameterItem {
    id?: number;
    idParameter: number;
    description?: string;
    parameter1?: string;
    parameter2?: string;
    parameter3?: string;
    startDate?: string;
    endDate?: string;
    usuario?: string;
    code?: number;
}

export class ParameterDetail {
    id: number;
    idItem: number;
    description: string;
    parameter1: string;
    descParameter1?: string;
    parameter2: string;
    descParameter2?: string;
    parameter3: string;
    startDate: string;
    endDate: string;
    usuario: string;
}
