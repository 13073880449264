import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export const dateLessThanValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const f = control.get('startDate');
    const t = control.get('endDate');
    
    if (f.value > t.value) {
      return {
        // dates: 'Date from must be less than Date to'
        'dateLessThan': true
      };
    }
    return {};
};
