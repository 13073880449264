import { Injectable } from '@angular/core';
import { DataService } from '../_architecture/data.service';
import { Result } from '../_architecture/envelopes';
import { HttpParams } from '@angular/common/http';
import { Parameter, ParameterItem, ParameterDetail } from '../_models/parameter.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private dataService: DataService) { }  

  //Retorna a lista de parâmetros
  public listParameters() {
    return this.dataService.get<Result<Parameter[]>>('/parameter/parameters');
  }

  //Retorna a lista de items do parâmetro
  public listParameterItems(idParameter: string) {
    const params = new HttpParams().append('idParameter', idParameter);
    return this.dataService.get<Result<ParameterItem[]>>('/parameter/items', params);
  }

  //Retorna a lista de detalhes do item do parâmetro
  public listParameterDetails(idItem: string) {
    const params = new HttpParams().append('idItem', idItem);
    return this.dataService.get<Result<ParameterDetail[]>>('/parameter/details', params);
  }

  //Inclui um parâmetro
  public createParameter(parameter: Parameter) {
    return this.dataService.post<Result<Parameter>>('/parameter/add', parameter);
  }

  //Carrega um parâmetro
  public loadParameter(id: number) {
    return this.dataService.get<Result<Parameter>>('/parameter/' + id, null);
  }

  //Atualiza um parâmetro
  public updateParameter(parameter: Parameter) {
    return this.dataService.put<Result<Parameter>>('/parameter/put', parameter);
  }

  //Exclui um parâmetro
  public deleteParameter(id: number) {
    return this.dataService.delete<Result<Parameter>>('/parameter/delete/' + id, null);
  }

  //Inclui um item de um parâmetro
  public createParameterItem(parameter: ParameterItem) {
    return this.dataService.post<Result<ParameterItem>>('/parameter/item/add', parameter);
  }

  //Carrega um item de um parâmetro
  public loadParameterItem(id: number) {
    return this.dataService.get<Result<ParameterItem>>('/parameter/item/' + id, null);
  }

  //Atualiza um item de um parâmetro
  public updateParameterItem(parameter: ParameterItem) {
    return this.dataService.put<Result<ParameterItem>>('/parameter/item/put', parameter);
  }

  //Exclui um item de um parâmetro
  public deleteParameterItem(id: number) {
    return this.dataService.delete<Result<ParameterItem>>('/parameter/item/delete/' + id, null);
  }

  //Inclui um detalhe de um item de um parâmetro
  public createParameterDetail(parameter: ParameterDetail) {
    return this.dataService.post<Result<ParameterDetail>>('/parameter/detail/add', parameter);
  }

  //Carrega um detalhe de um item de um parâmetro
  public loadParameterDetail(id: number) {
    return this.dataService.get<Result<ParameterDetail>>('/parameter/detail/' + id, null);
  }

  //Atualiza um detalhe de um item de um parâmetro
  public updateParameterDetail(parameter: ParameterDetail) {
    return this.dataService.put<Result<ParameterDetail>>('/parameter/detail/put', parameter);
  }

  //Exclui um detalhe de um item de um parâmetro
  public deleteParameterDetail(id: number) {
    return this.dataService.delete<Result<ParameterDetail>>('/parameter/detail/delete/' + id, null);
  }
}