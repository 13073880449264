import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from "ngx-simple-modal";
import { ParameterDetail, Parameter, ParameterItem } from 'src/app/_models/parameter.model';
import { AdminService } from 'src/app/_services/admin.service';

export interface ConfirmParameterDetailModel {
  parameter: ParameterDetail;
}

@Component({
  selector: 'app-confirm-parameter-detail',
  templateUrl: './confirm-parameter-detail.component.html',
  styleUrls: ['./confirm-parameter-detail.component.css']
})
export class ConfirmParameterDetailComponent extends SimpleModalComponent<ConfirmParameterDetailModel, boolean> implements ConfirmParameterDetailModel, OnInit {

  parameter: ParameterDetail;
  parameters: Parameter[];
  parametersItems: ParameterItem[];

  constructor(private adminService: AdminService) {

    super();
    this.parameter = new ParameterDetail();
  }

  ngOnInit() {
    this.adminService.listParameters().subscribe((result) => {
      this.parameters = result.content;
    });
  }

  confirm() {
    this.adminService.deleteParameterDetail(this.parameter.id).subscribe((result) => {
      console.log('Parameter Detail Deleted!');
    });

    this.result = true;
    this.close();
  }
}
