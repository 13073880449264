import { UserProfile } from './user-profile';
import { SimpleClaim } from './simple-claim';

export class AuthContext {
  username?: string;
  name?: string;
  registry?: string;
  email?: string;
  roles?: string[];
 
  get isAdmin() {
    return !!this.roles && !!this.roles.find(c =>
      c === 'Admin');
  }
}