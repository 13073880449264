import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderModule } from 'ngx-order-pipe';

import { AppComponent } from './app.component';
import { UserService } from './_services/user.service';
import { HomeComponent } from './home/home.component';
import { WorkscheduleComponent } from './workschedule/workschedule.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ErrorComponent } from './error/error.component';
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { HistoryComponent } from './history/history.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AuthService } from './_guards/auth.service';
import { AdminService } from './_services/admin.service';
import { AuthGuard } from './_guards/auth.guard';
import { DataService } from './_architecture/data.service';
import { DpDatePickerModule } from 'ng2-date-picker';
import { FoodServiceComponent } from './workschedule/food-service/food-service.component';
import { TransportServiceComponent } from './workschedule/transport-service/transport-service.component';
import { SupportServiceComponent } from './workschedule/support-service/support-service.component';
import { CreateParameterComponent } from './admin/create-parameter/create-parameter.component';
import { ParamModalComponent } from './param-modal/param-modal.component';
import { ConfirmParameterComponent } from './admin/create-parameter/confirm-parameter/confirm-parameter.component';
import { ConfirmParameterItemComponent } from './admin/create-parameter-item/confirm-parameter-item/confirm-parameter-item.component';
import { CreateParameterItemComponent } from './admin/create-parameter-item/create-parameter-item.component';
import { CreateParameterDetailComponent } from './admin/create-parameter-detail/create-parameter-detail.component';
import { ConfirmParameterDetailComponent } from './admin/create-parameter-detail/confirm-parameter-detail/confirm-parameter-detail.component';
import { ErroModalngComponent } from './erro-modalng/erro-modalng.component';
import { WorkscheduleConfirmationModalComponent } from './workschedule/workschedule-modal-confirmation/workschedule-modal-confirmation.component';
import { DataSharingService } from './_services/data-sharing.service';
import { SortPipe } from './pipe/sortBy.pipe';
import { FilterPipe, FilterTipoColabPipe } from './pipe/filter.pipe';
import { ReportComponent } from './report/report.component';
import { ExcelService } from './_services/excel.service';
import { ConfigurationService } from './_architecture/configuration.service';
import { WorkscheduleSummaryComponent } from './workschedule/workschedule-summary/workschedule-summary.component';
import { WorkscheduleModelDeleteComponent } from './workschedule/workschedule-modal-delete/workschedule-modal-delete.component';
import { DisableControlDirective } from './pipe/disable-directive';
import { AdminGuard } from './_guards/admin.guard';
import { SigninRedirectCallbackComponent } from './home/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './home/signout-redirect-callback.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import { AuthInterceptorService } from './_guards/auth-interceptor.service';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#fffefc',
  fgsType: 'circle',
  hasProgressBar: false,
  text: 'Loading'
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WorkscheduleComponent,
    WorkscheduleConfirmationModalComponent,
    FooterComponent,
    HeaderComponent,
    ErrorComponent,
    HistoryComponent,
    AdminComponent,
    LoginComponent,
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent,
    UnauthorizedComponent,
    FoodServiceComponent,
    TransportServiceComponent,
    SupportServiceComponent,
    ConfirmParameterComponent,
    ConfirmParameterItemComponent,
    ConfirmParameterDetailComponent,
    CreateParameterComponent,
    CreateParameterItemComponent,
    CreateParameterDetailComponent,
    ParamModalComponent,
    ErroModalngComponent,
    SortPipe,
    FilterPipe,
    FilterTipoColabPipe,
    ReportComponent,
    WorkscheduleSummaryComponent,
    WorkscheduleModelDeleteComponent,
    DisableControlDirective
  ],
  entryComponents: [
    WorkscheduleComponent,
    WorkscheduleConfirmationModalComponent,
    WorkscheduleModelDeleteComponent,
    ConfirmParameterItemComponent,
    ConfirmParameterComponent,
    CreateParameterComponent,
    CreateParameterItemComponent,
    CreateParameterDetailComponent,
    ConfirmParameterDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule,
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    AngularFontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleModalModule.forRoot({container: document.body}),
    // BootstrapModalModule.forRoot({container: document.body}),
    DpDatePickerModule,
    OrderModule
  ],
  exports: [
    WorkscheduleComponent,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule,
    ConfirmParameterComponent,
    ConfirmParameterItemComponent,
    ConfirmParameterDetailComponent,
    CreateParameterComponent,
    CreateParameterItemComponent,
    CreateParameterDetailComponent,
    ParamModalComponent,
    WorkscheduleConfirmationModalComponent
  ],
  providers: [
    AdminService,
    AuthService,
    AuthGuard,
    AdminGuard,
    DataService,
    UserService,
    DataSharingService,
    ExcelService,
    ConfigurationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
