import { Router } from '@angular/router';
import { WorkscheduleModelDeleteComponent } from './../workschedule/workschedule-modal-delete/workschedule-modal-delete.component';
import { Component, OnInit } from '@angular/core';
import { UtilService } from '../_services/util.service';
import { WorkscheduleService } from '../_services/workschedule.service';
import { WorkSchedule } from '../_models/workschedule.model';
import { IDatePickerConfig } from 'ng2-date-picker';
import { ParameterItem } from '../_models/parameter.model';
import { AreaServices } from '../_models/area.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { dateLessThanValidator } from '../workschedule/workschedule.validator';
import { CurrentUser } from '../_models/current-user.model';
import { SimpleModalService } from "ngx-simple-modal";
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})

export class HistoryComponent implements OnInit {

  workschedules: WorkSchedule[];
  workschedule: WorkSchedule;
  horarios: ParameterItem[];
  areas: AreaServices[];
  historyScheduleForm: FormGroup;
  selectedHorario: number;
  selectedArea: string;
  userLogged: CurrentUser;
  isAdmin: string;

  dateConfig: IDatePickerConfig = {
    locale: 'pt',
    format: 'DD/MM/YYYY',
    showMultipleYearsNavigation: true,
    disableKeypress: true
  };

  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private workScheduleService: WorkscheduleService,
    private dialogService: SimpleModalService,
    private router: Router,
    private userService: UserService
  ) {
    this.workschedule = new WorkSchedule();
  }

  ngOnInit() {
    this.utilService.listHours().subscribe((result) => {
      this.horarios = result.content;
    });

    this.utilService.listAreas().subscribe((result) => {
      this.areas = result.content;
    });

    
    const user = this.userService.getCurrentUser();
    this.isAdmin = user.isAdmin ? 'S' : 'N';

    this.createForm();
    this.consultar();
  }

  formErrors(name: string) {
    if (this.historyScheduleForm.touched) {
      if (this.historyScheduleForm.errors) {
        return this.historyScheduleForm.errors[name];
      } else {
        return false;
      }
    }
  }

  createForm() {
    this.historyScheduleForm = this.formBuilder.group({
      codEnd: [this.workschedule.codEnd, Validators.required],
      dataInicio: [this.workschedule.dataInicio, Validators.required],
      dataFim: [this.workschedule.dataFim, [Validators.required]],
      periodo: [this.workschedule.periodo, Validators.required],
    }, { validator: dateLessThanValidator });
  }

  get f() { return this.historyScheduleForm.controls; }

  consultar() {
    this.workschedule.registroUsuario = +this.userService.getCurrentUser().registry;
    this.workScheduleService.listWorkSchedules(this.workschedule).subscribe((result) => {
      this.workschedules = result.content.page;
    });
  }

  editWorkschedule(workschedule: WorkSchedule) {
    location.href = '/workschedule/edit/' + workschedule.id;
  }

  rescheduleWorkschedule(workschedule: WorkSchedule) {
    location.href = '/workschedule/re-schedule/' + workschedule.id;
  }

  deleteWorkschedule(workschedule: WorkSchedule) {
    this.workScheduleService.delete(workschedule.id).subscribe(result => {
      this.consultar();
    });
  }

  isPast(workschedule: WorkSchedule) {

    let endDate = workschedule.dataFim;

    let year = parseInt(endDate.substr(6, 4));
    let month = parseInt(endDate.substr(3, 2)) - 1;
    const day = parseInt(endDate.substr(0, 2));
    let newDate = new Date(year, month, day);
    let currentDate = new Date();

    return (newDate < currentDate);
  }

  isFuture(workschedule: WorkSchedule) {
    
    let startDate = workschedule.dataInicio;
    let periodo = workschedule.periodo;

    let year = parseInt(startDate.substr(6, 4));
    let month = parseInt(startDate.substr(3, 2)) - 1;
    const day = parseInt(startDate.substr(0, 2));

    const hour = parseInt(periodo.substr(0, 2));
    const minute = parseInt(periodo.substr(3, 2));

    let newDate = new Date(year, month, day, hour, minute);
    let currentDate = new Date();

    console.log('isFuture', newDate > currentDate);

    return (newDate > currentDate);
  }

  showConfirm(workschedule: WorkSchedule) {
    this.dialogService.addModal(WorkscheduleModelDeleteComponent, { workschedule })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
            this.deleteWorkschedule(workschedule);
        }
      });
  }
}
