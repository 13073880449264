import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {

    transform(items: any[], value: string): any[] {
        if (!items) { return []; } //não é um array
        if (!value) { return items; } //array de um único item

        value = value.toLowerCase();

        return items.filter(i => {
            return i.name.toLowerCase().includes(value)
                || i.register.toString().includes(value);
        });
    }
}

@Pipe({ name: 'filterColab' })
export class FilterTipoColabPipe implements PipeTransform {

    transform(items: any[], value: string): any[] {
        if (!items) { return []; } //não é um array
        if (!value) { return items; } //array de um único item

        if (value === 'C') {
            return items.filter(i => {return i.company === '01'});
        }
        else if (value === 'T') {
            return items.filter(i => {return i.company !== '01'});
        } else { return items; }
    }
}