import { Component, OnInit, ElementRef, Output, HostListener, EventEmitter } from '@angular/core';
import { Parameter, ParameterItem, ParameterDetail } from '../_models/parameter.model';
import { SimpleModalService } from "ngx-simple-modal";
import { AdminService } from '../_services/admin.service';
import { CreateParameterComponent } from './create-parameter/create-parameter.component';
import { CreateParameterDetailComponent } from './create-parameter-detail/create-parameter-detail.component';
import { ConfirmParameterComponent } from './create-parameter/confirm-parameter/confirm-parameter.component';
import { ConfirmParameterItemComponent } from './create-parameter-item/confirm-parameter-item/confirm-parameter-item.component';
import { CreateParameterItemComponent } from './create-parameter-item/create-parameter-item.component';
import { ConfirmParameterDetailComponent } from './create-parameter-detail/confirm-parameter-detail/confirm-parameter-detail.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  parameter: Parameter;
  parameterItem: ParameterItem;
  parameters: Parameter[];
  parameterItems: ParameterItem[];
  parameterDetails: ParameterDetail[];
  showParameterDiv: boolean = true;
  showParameterItemDiv: boolean = false;
  showParameterDetailDiv: boolean = false;

  modalOptions: any = {
    backdropColor: 'rgba(0,0,0,0.7)'
  };

  constructor(private dialogService: SimpleModalService,
    private adminService: AdminService) { }

  ngOnInit() {
    this.adminService.listParameters().subscribe((result) => {
      this.parameters = result.content;

      this.showParameterDiv = true;
      this.showParameterItemDiv = false;
      this.showParameterDetailDiv = false;
    });
  }

  //get f() { return this.parameterForm.controls; }

  loadItemsByParameter(parameter) {
    this.adminService.listParameterItems(parameter.id).subscribe((result) => {
      this.parameterItems = result.content;

      this.parameter = parameter;
      this.showParameterDiv = false;
      this.showParameterItemDiv = true;
      this.showParameterDetailDiv = false;
    });
  }

  loadDetailsByParameter(item) {
    this.adminService.listParameterDetails(item.id).subscribe((result) => {
      this.parameterDetails = result.content;
      this.parameterItem = item;
      this.showParameterDetailDiv = true;
    });
  }

  //Dialog Parameter
  addParameterDialog() {
    this.dialogService.addModal(CreateParameterComponent, { parameter: null }, this.modalOptions).subscribe(
      (result) => {

        if (undefined !== result)
          if (result.id > 0)
            this.parameters.push(result);
      }
    );
  }

  editParameterDialog(parameter: Parameter) {
    this.dialogService.addModal(CreateParameterComponent, { parameter: parameter }, this.modalOptions).subscribe(
      () => { }
    );
  }

  deleteParameterDialog(parameter: Parameter) {
    this.dialogService.addModal(ConfirmParameterComponent, { parameter: parameter }, this.modalOptions).subscribe(
      (result) => {
        if (result) {
          let index = this.parameters.findIndex(param => param.id === parameter.id);
          if (index > -1)
            this.parameters.splice(index, 1);
        }
      }
    );
  }

  //Dialog Parameter Item
  addParameterItemDialog(parameter: Parameter) {
    this.dialogService.addModal(CreateParameterItemComponent, { parameter: { idParameter: parameter.id } }, this.modalOptions).subscribe(
      (result) => {

        if (undefined !== result)
          if (result.id > 0)
            this.parameterItems.push(result);
      }
    );
  }

  editParameterItemDialog(parameter: ParameterItem) {
    this.dialogService.addModal(CreateParameterItemComponent, { parameter: parameter }, this.modalOptions).subscribe(
      () => { }
    );
  }

  deleteParameterItemDialog(parameter: ParameterItem) {
    this.dialogService.addModal(ConfirmParameterItemComponent, { parameter: parameter }, this.modalOptions).subscribe(
      (result) => {
        if (result) {
          let index = this.parameterItems.findIndex(param => param.id === parameter.id);
          if (index > -1)
            this.parameterItems.splice(index, 1);
        }
      }
    );
  }

  //Dialog Parameter Detail
  addParameterDetailDialog(parameter: ParameterItem) {
    this.dialogService.addModal(CreateParameterDetailComponent, { parameterItemId:  parameter.id }, this.modalOptions).subscribe((result) => {

        if (undefined !== result)
          if (result.id > 0)
            this.parameterDetails.push(result);
      }
    );
  }

  editParameterDetailDialog(parameter: ParameterDetail) {
    
    this.dialogService.addModal(CreateParameterDetailComponent, { parameter: parameter }, this.modalOptions).subscribe(
      () => { }
    );
  }

  deleteParameterDetailDialog(parameter: ParameterDetail) {
    this.dialogService.addModal(ConfirmParameterDetailComponent, { parameter: parameter }, this.modalOptions).subscribe(
      (result) => {
        if (result) {
          let index = this.parameterDetails.findIndex(param => param.id === parameter.id);
          if (index > -1)
            this.parameterDetails.splice(index, 1);
        }
      }
    );
  }

  showParameters() {
    this.showParameterDiv = true;
    this.showParameterItemDiv = false;
    this.showParameterDetailDiv = false;
  }

  showParametersItens() {
    this.showParameterDiv = false;
    this.showParameterItemDiv = true;
    this.showParameterDetailDiv = false;
  }
}
