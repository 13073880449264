import { AsyncValidatorFn, ValidatorFn, FormGroup, ValidationErrors, AbstractControl } from '@angular/forms';
import { WorkSchedule } from '../_models/workschedule.model';
import { map } from 'rxjs/operators';
import { WorkscheduleService } from '../_services/workschedule.service';
import { Observable } from 'rxjs/internal/Observable';

function parseDate(input) {
    const parts = input.match(/(\d+)/g);
    // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[2], parts[1] - 1, parts[0]); // months are 0-based
}

export const dateLessThanValidator: ValidatorFn = (fg: FormGroup): ValidationErrors | null => {
    let dataInicio = fg.get('dataInicio');
    let dataFim = fg.get('dataFim');

    if (!dataInicio.value || !dataFim.value) { return null; }

    return (parseDate(dataInicio.value.toString()) > parseDate(dataFim.value.toString())) ? { 'dateLessThan': true } : null;
};

// export function existingRegisterScheduledValidator(workScheduleService: WorkscheduleService, workschedule: WorkSchedule): AsyncValidatorFn {
//     return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
//         return workScheduleService.verifyExistingRegisterScheduled(workschedule).pipe(map(result => {
//             debugger;
//             return (result.content && result.content.length > 0) ? { 'registerExists': true } : null;
//         }));
//     }
// };

