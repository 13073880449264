import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class ConfigurationService {

    constructor(private http: HttpClient) { }

    public getWebApiEndpoint() {
        return window.location.host.indexOf('localhost') > -1 ? 'https://localhost:44317/api' : 'https://h5vh4ha3gb.execute-api.sa-east-1.amazonaws.com/Stage/api';
    }    
    
    public getAuthServiceEndpoint() {
        return 'https://fg.ciam.preprod.aws.scania.com/auth/realms/scania/';
    }
}
