import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transport-service',
  templateUrl: './transport-service.component.html',
  styleUrls: ['./transport-service.component.css']
})
export class TransportServiceComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
