import { Colaborator } from './colaborator.model';
import { AreaServices } from './area.model';

export class WorkSchedule {
    id: number;
    cCusto: number;
    codEnd: string;
    empresaCodEnd: string;
    dataInclusao: string;
    dataInicio: string;
    dataFim: string;
    periodo: string;
    idPeriodo: number;
    justificativa: string;
    colaboradores: Colaborator[];
    services: AreaServices[];
    totalColaboradores: number;
    totalTerceiros: number;
    empresaUsuario: string;
    registroUsuario: number;
    nomeUsuario: string;
    considerarFdsFeriado: string;
    suporteIt: boolean;

    constructor() {
      this.considerarFdsFeriado = 'N';
    }
}

export class WorkScheduleTotais {
    servico: string;
    quantidadeColaboradores: number;
    quantidadeTerceiros: number;
}
