import { Component, OnInit, Input } from '@angular/core';
import { ParameterDetail } from 'src/app/_models/parameter.model';
import { WorkSchedule } from 'src/app/_models/workschedule.model';

@Component({
  selector: 'app-food-service',
  templateUrl: './food-service.component.html',
  styleUrls: ['./food-service.component.css']
})
export class FoodServiceComponent implements OnInit {

  @Input() foodServices: ParameterDetail[];
  @Input() workschedule: WorkSchedule;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: ParameterDetail[]) {
  }
}
