import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from "ngx-simple-modal";
import { AdminService } from 'src/app/_services/admin.service';
import { WorkSchedule } from 'src/app/_models/workschedule.model';

export interface IWorkscheduleConfirmationModal {
  message: string;
}

@Component({
  selector: 'app-workschedule-modal-confirmation.component.html',
  templateUrl: './workschedule-modal-confirmation.component.html',
  styleUrls: ['./workschedule-modal-confirmation.component.css']
})
export class WorkscheduleConfirmationModalComponent extends SimpleModalComponent<IWorkscheduleConfirmationModal, string> implements IWorkscheduleConfirmationModal, OnInit {

  message: string;

  constructor(private adminService: AdminService) {
    super();
    this.message = '';
  }

  ngOnInit() {
  }

  confirm() {
    //this.adminService.deleteParameterItem(this.workschedule.id).subscribe((result) => {
      //console.log('Workschedule Deleted!');
    //});

    //this.result = true;
    //this.close();
  }
}
