import { Component, OnInit } from '@angular/core';
import { IDatePickerConfig } from 'ng2-date-picker';
import { TransporteColaborador, Relatorio, RefeicaoColaborador, Filtro } from '../_models/report.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReportService } from '../_services/report.service';
import { ExcelService } from '../_services/excel.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  reportData:  any[];
  relatorio: Relatorio;
  filtro: Filtro;
  reportForm: FormGroup;
  selectedReport: string;
  showGrid: boolean = false;
  submitted: boolean = false;
  dataSet: any[];

  constructor(private formBuilder: FormBuilder,
    private reportService: ReportService,
    private excelService: ExcelService) {

    this.relatorio = new Relatorio();
    this.filtro = new Filtro();
    this.reportData = [];
  }

  dateConfig: IDatePickerConfig = {
    locale: 'pt',
    format: 'DD/MM/YYYY',
    showMultipleYearsNavigation: true,
    disableKeypress: true
  };

  get f() { return this.reportForm.controls; }

  get form() { return this.reportForm; }

  ngOnInit() {
    this.createForm();
  }

  changeReport() {
    this.reportData = [];
    this.filtro.codRel = parseInt(this.selectedReport);
  }

  clearZero(text: any) {
    if(text == 0) {
      return '';
    } else {
      return text;
    }
  }

  onSubmit() {
    this.submitted = true;

    //Se houver algum erro do formulário
    if (this.reportForm.invalid) {
      //Sobe a barra de rolagem para o topo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }

    this.reportService.getReportList(this.filtro).subscribe((result) => {

      this.reportData = [];
      
      result.content.forEach(item => {
        this.reportData.push(item);
      });

      this.showGrid = true;
    });
  }

  createForm() {
    this.reportForm = this.formBuilder.group({
      codRel: [this.filtro.codRel, Validators.required],
      dataInicio: [this.filtro.dataInicio, Validators.required],
      dataFim: [this.filtro.dataFim, Validators.required]
    });
  }

  generateTransportFile() {

    this.filtro.codRel = 4.1;
    this.reportService.getReportList(this.filtro).subscribe((result) => {
      this.excelService.exportAsExcelFile(result.content, 'Report-Transporte-Completo');
    });
  }

  generateAssetSecurityFile() {

    this.filtro.codRel = 5.1;
    this.reportService.getReportList(this.filtro).subscribe((result) => {
      this.excelService.exportAsExcelFile(result.content, 'Report-Seguranca-Completo');
    });
  }

  generateITSupportFile() {

    this.filtro.codRel = 6.1;
    this.reportService.getReportList(this.filtro).subscribe((result) => {
      this.excelService.exportAsExcelFile(result.content, 'Report-SuporteIt-Completo');
    });
  }

  exportAsXLSX(): void {
    let report_name;

    switch(this.selectedReport) {
      case '1':
        report_name = 'Report-Transporte';
        break;
      case '2':
        this.generateTransportFile();
        break;
      case '3':
        report_name = 'Report-Refeicao';
        break;
      case '4':
        report_name = 'Report-Refeicao-Completo';
        break;
      case '5':
        this.generateAssetSecurityFile();
        break;
      case '6':
        this.generateITSupportFile();
        break;
    }

    if(this.selectedReport !== '2' && this.selectedReport !== '5' && this.selectedReport !== '6') {
      this.excelService.exportAsExcelFile(this.reportData, report_name);
    }
  }
}