import { Component, OnInit } from '@angular/core';
import { WorkSchedule, WorkScheduleTotais } from 'src/app/_models/workschedule.model';
import { WorkscheduleService } from 'src/app/_services/workschedule.service';
import { UtilService } from 'src/app/_services/util.service';
import { UserService } from 'src/app/_services/user.service';
import { Colaborator } from 'src/app/_models/colaborator.model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-workschedule-summary',
  templateUrl: './workschedule-summary.component.html',
  styleUrls: ['./workschedule-summary.component.css']
})
export class WorkscheduleSummaryComponent implements OnInit {
  workschedule: WorkSchedule;
  workscheduleTotais: WorkScheduleTotais[];
  colaborators: Colaborator[];

  constructor(private workScheduleService: WorkscheduleService, private router: Router) {
    console.log('this.workschedule', this.workschedule);
  }

  ngOnInit() {
    this.workschedule = this.workScheduleService.workschedule;
    this.workscheduleTotais = this.workScheduleService.workscheduleTotais;
  }

  //Salva a programação de trabalho
  save() {
    if (this.workschedule.id > 0)
      this.update();
    else
      this.create();
  }

  //Cria uma nova programação de trabalho
  create() {
    this.workScheduleService.create(this.workschedule)
      .subscribe(
        result => {
          this.router.navigateByUrl('/history');
        },
        error => {
        });
  }

  //Atualiza uma programação de trabalho
  update() {
    this.workScheduleService.update(this.workschedule)
      .subscribe(
        result => {
          this.router.navigateByUrl('/history');
        },
        error => {
        });
  }
}
