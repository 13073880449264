import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from "ngx-simple-modal";
import { ParameterItem } from 'src/app/_models/parameter.model';
import { AdminService } from 'src/app/_services/admin.service';

export interface ConfirmParameterItemModel {
  parameter: ParameterItem;
}

@Component({
  selector: 'app-confirm-parameter-item',
  templateUrl: './confirm-parameter-item.component.html',
  styleUrls: ['./confirm-parameter-item.component.css']
})
export class ConfirmParameterItemComponent extends SimpleModalComponent<ConfirmParameterItemModel, boolean> implements ConfirmParameterItemModel, OnInit {

  parameter: ParameterItem;

  constructor(private adminService: AdminService) {

    super();
    this.parameter = new ParameterItem();
  }

  ngOnInit() {
  }

  confirm() {
    this.adminService.deleteParameterItem(this.parameter.id).subscribe((result) => {
      console.log('Parameter Item Deleted!');
    });

    this.result = true;
    this.close();
  }
}
