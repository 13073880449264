import { Injectable } from '@angular/core';
import { DataService } from '../_architecture/data.service';
import { Result } from '../_architecture/envelopes';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Parameter, ParameterItem, ParameterDetail } from '../_models/parameter.model';
import { Colaborator } from '../_models/colaborator.model';
import { Area } from '../_models/area.model';
import { LinhaTransporte } from '../_models/linha-transporte.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private dataService: DataService,
    private userService: UserService) { }

  //Retorna a lista de parâmetros
  public listHours() {
    return this.dataService.get<Result<ParameterItem[]>>('/listHours');
  }

  public listAreas() {
    const params = new HttpParams().append('user.registry', this.userService.getCurrentUser().registry);
    return this.dataService.get<Result<Area[]>>('/areas', params);
  }

  public listColaborators(codend: string) {
    const params = new HttpParams().append('area.codEnd', codend);
    return this.dataService.get<Result<Colaborator[]>>('/colaborators', params);
  }

  public listTransportLines() {
    return this.dataService.get<Result<ParameterDetail[]>>('/listTransportLines');    
  }

  public listFoodServices() {
    return this.dataService.get<Result<ParameterItem[]>>('/listFoodServices', null);
  }

  public getFoodServicesByItem(idItem: number) {
    return this.dataService.get<Result<ParameterDetail[]>>('/getFoodServicesByItem/' + idItem, null);
  }

  public getHomeScreenMessage() {
    return this.dataService.get<string>('/gethomescreenmessage', null);
  }
}
