import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private renderer: Renderer2) {    }

  ngOnInit(): void {
    if (window.location.host.indexOf('localhost') > -1) {
      this.renderer.setAttribute(document.body, 'data-env', 'DEV');
    } else if (window.location.host.indexOf('dev-') > -1) {
      this.renderer.setAttribute(document.body, 'data-env', 'DEV');
    } else if (window.location.host.indexOf('acp-') > -1) {
      this.renderer.setAttribute(document.body, 'data-env', 'ACP');
    }
  }  
}
