import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_guards/auth.service';
import { CurrentUser } from '../_models/current-user.model';
import { UserService } from '../_services/user.service';
import { DataSharingService } from '../_services/data-sharing.service';
import { Router } from '@angular/router';
declare var window: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userLogged: CurrentUser;
  isAdmin = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private dataSharingService: DataSharingService
    ) { }

  ngOnInit() {
    window.CorporateUi.baseComponents();

    // this.dataSharingService.userLogged.subscribe( userLogged => {

    //   if (this.userService.userIsLogged()) {
    //     this.userLogged = this.userService.userLogged;
    //     this.isAdmin = this.userLogged.admin === 'S';
    //   }
    //   else {
    //     this.userLogged = null;
    //   }
    // });    
  }

  logout() {
    // this.userService.logout();
    window.location.reload();
  }
}
