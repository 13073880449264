import { Component, OnInit } from '@angular/core';
import { IDatePickerConfig } from 'ng2-date-picker';
import { SimpleModalComponent } from "ngx-simple-modal";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Parameter } from 'src/app/_models/parameter.model';
import { AdminService } from 'src/app/_services/admin.service';
import { dateLessThanValidator } from './create-parameter.validator';
import { Location } from '@angular/common';

export interface CreateParameterModel {
  parameter: Parameter;
}

@Component({
  selector: 'app-create-parameter',
  templateUrl: './create-parameter.component.html',
  styleUrls: ['./create-parameter.component.css']
})
export class CreateParameterComponent extends SimpleModalComponent<CreateParameterModel, Parameter> implements CreateParameterModel, OnInit {  
  parameter: Parameter;
  parameterForm: FormGroup;
  isFormError: boolean;
  mensagemErro: string;

  dateConfig: IDatePickerConfig = {
    locale: 'pt',
    format: 'DD/MM/YYYY',
    showMultipleYearsNavigation: true,
    disableKeypress: true
  };

  constructor(private formBuilder: FormBuilder,
    private adminService: AdminService,
    private location: Location) {

    super();
    this.parameter = new Parameter();
    this.isFormError = false;
    this.mensagemErro = '';
  }

  ngOnInit() {

    this.createForm();

    //if (this.parameter.id > 0) {
    //this.loadParameter();
    //}
  }

  loadParameter() {
    //this.adminService.loadParameter(this.parameter.id).subscribe((result) => {
    //this.parameter = result.content;
    //});
  }

  createForm() {
    this.parameterForm = this.formBuilder.group({
      code: [this.parameter.code, Validators.required],
      description: [this.parameter.description, Validators.required],
      startDate: [this.parameter.startDate, Validators.required],
      endDate: [this.parameter.endDate, Validators.required]
    }, { validator: dateLessThanValidator });
  }

  get f() { return this.parameterForm.controls; }
  
  confirm() {
    
    this.validate();

    if (this.parameter.id > 0)
      this.update();
    else
      this.create();
  }

  validate() {
    
    if(null !== this.parameterForm.controls.code.errors && this.parameterForm.controls.code.errors.required)
    {
      this.isFormError = true;
      this.mensagemErro = 'O código é obrigatório.';
      return;
    }

    if(null !== this.parameterForm.controls.description.errors && this.parameterForm.controls.description.errors.required)
    {
      this.isFormError = true;
      this.mensagemErro = 'A descrição é obrigatória.';
      return;
    }

    this.isFormError = false;
    this.mensagemErro = '';
  }

  update() {
    this.validate();

    this.adminService.updateParameter(this.parameter).subscribe((result) => {      
      this.location.go('/');
    });
  }

  create() {
    this.validate();

    this.adminService.createParameter(this.parameter).subscribe((result) => {
      this.parameter.id = result.content.id;      
      
      this.location.go('/');
    });
  }
}
