import { Component, OnInit } from '@angular/core';
import { IDatePickerConfig } from 'ng2-date-picker';
import { SimpleModalComponent } from "ngx-simple-modal";
import { ParameterDetail, Parameter, ParameterItem } from 'src/app/_models/parameter.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { dateLessThanValidator } from './create-parameter-detail.validator';
import { AdminService } from 'src/app/_services/admin.service';

export interface CreateParameterDetailModel {
  parameterItemId?: number;
  parameter?: ParameterDetail;
}

@Component({
  selector: 'app-create-parameter-detail',
  templateUrl: './create-parameter-detail.component.html',
  styleUrls: ['./create-parameter-detail.component.css']
})
export class CreateParameterDetailComponent extends SimpleModalComponent<CreateParameterDetailModel, ParameterDetail> implements CreateParameterDetailModel, OnInit {
  parameter: ParameterDetail;
  parameters: Parameter[];
  parametersItems: ParameterItem[];
  selectedParameter: string;
  selectedParameterItem: string;
  detailForm: FormGroup;
  parameterItemId: number;

  dateConfig: IDatePickerConfig = {
    locale: 'pt',
    format: 'DD/MM/YYYY',
    showMultipleYearsNavigation: true,
    disableKeypress: true
  };

  constructor(private formBuilder: FormBuilder,
              private adminService: AdminService) {

    super();
  }

  ngOnInit() {
    
    this.adminService.listParameters().subscribe((result) => {
      this.parameters = result.content;

      
      if (this.parameter && this.parameter.parameter1) {
        this.adminService.listParameterItems(this.parameter.parameter1).subscribe((result) => {
          this.parametersItems = result.content;
        });
      }
  
      if (!this.parameter) {
        this.parameter = {
          id: 0,
          idItem: this.parameterItemId,
          startDate: '',
          endDate: '',
          description: '',
          parameter1: '0',
          parameter2: '0',
          parameter3: '',
          usuario: ''
        }
      }
       
      this.createForm(this.parameter);
    });
  }

  createForm(parameter: ParameterDetail) {
    
    this.detailForm = this.formBuilder.group({
      parameter1: parameter.parameter1,
      parameter2: parameter.parameter2,
      parameter3: parameter.parameter3,
      description: parameter.description,
      startDate: parameter.startDate,
      endDate: parameter.endDate,
      id: parameter.id,
      idItem: parameter.idItem,
      usuario: parameter.usuario,
    }, { validator: dateLessThanValidator });
  }

  control(name: string): FormControl {
    return (this.detailForm.controls[name]) as FormControl;
  }

  formErrors(name: string) {
    if (this.detailForm.touched) {
      if (this.detailForm.errors) {
        return this.detailForm.errors[name];
      } else {
        return false;
      }
    }
  }

  confirm() {
    console.log(this.detailForm.value);

    if (this.parameter.id > 0)
      this.update();
    else
      this.create();

    this.result = this.parameter;
    this.close();
  }

  changeParameter(){
    this.adminService.listParameterItems(this.control('parameter1').value).subscribe((result) => {
      this.parametersItems = result.content;
    });
  }

  update() {
    this.adminService.updateParameterDetail(this.detailForm.value).subscribe((result) => {
      console.log('Parameter Detail Updated!');
    });
  }

  create() {
    this.adminService.createParameterDetail(this.detailForm.value).subscribe((result) => {
      this.parameter.id = result.content.id;
      console.log('Parameter Detail Created!');
    });
  }
}
