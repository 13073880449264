import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { WorkscheduleComponent } from './workschedule/workschedule.component';
import { HomeComponent } from './home/home.component';
import { HistoryComponent } from './history/history.component';
import { AdminComponent } from './admin/admin.component';
import { ErrorComponent } from './error/error.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { FoodServiceComponent } from './workschedule/food-service/food-service.component';
import { TransportServiceComponent } from './workschedule/transport-service/transport-service.component';
import { ReportComponent } from './report/report.component';
import { WorkscheduleSummaryComponent } from './workschedule/workschedule-summary/workschedule-summary.component';
import { AdminGuard } from './_guards/admin.guard';
import { SigninRedirectCallbackComponent } from './home/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './home/signout-redirect-callback.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'signin-callback', component: SigninRedirectCallbackComponent },
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
  { path: 'workschedule/edit/:id', component: WorkscheduleComponent, canActivate: [AuthGuard] },
  { path: 'workschedule/summary', component: WorkscheduleSummaryComponent, canActivate: [AuthGuard] },
  { path: 'workschedule/re-schedule/:id', component: WorkscheduleComponent, canActivate: [AuthGuard] },
  { path: 'workschedule', component: WorkscheduleComponent, canActivate: [AuthGuard] },  
  { path: 'foodservice', component: FoodServiceComponent, canActivate: [AuthGuard] },
  { path: 'transportservice', component: TransportServiceComponent, canActivate: [AuthGuard] },
  { path: 'history', component: HistoryComponent, canActivate: [AuthGuard] },
  { path: 'report', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'report/:report', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },  
  { path: 'login', component: LoginComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
