
export class Constants {
	// public static clientRoot = 'http://localhost:4200/';
	public static clientRoot = 'https://progtrab.sla01.devtest.aws.scania.com/';

	// public static apiRoot = 'https://localhost:44317/api/';
	public static apiRoot = 'https://h5vh4ha3gb.execute-api.sa-east-1.amazonaws.com/Stage/api/';

	public static stsAuthority = 'https://fg.ciam.preprod.aws.scania.com/auth/realms/scania/';


	public static clientId = 'prog-trabalho-dev';
	// public static clientId = 'Od8mbaoCGeXTF9ZWeQJspTTd7Qf1v1A7';
}
